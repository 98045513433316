import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';

import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg';
import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase';
import courseTheme from '@babelcoder/gatsby-theme-courses/src/constants/theme';
import CertGeneration from '@babelcoder/gatsby-theme-courses/src/components/courses/CertGeneration';
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';

const Wrapper = styled.div`
  position: relative;
  display: flow-root;
  margin: ${courseTheme.gutter} auto 0 auto;

  ${up('large')} {
    padding: 0 ${courseTheme.gutter};
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

function Certification({ slug, name, title }) {
  const { search } = useLocation();
  const { user } = useGlobalState();
  const searchParams = parse(search);
  const [currentCert, setCurrentCert] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const loadCurrentCert = useCallback(async () => {
    // if (!user) return;

    setIsLoading(true);
    const firestore = await getFirebaseFirestore();
    const certSnapshot = await firestore.doc(`certs/${searchParams.id}`).get();
    const certData = certSnapshot.data();

    if (certData) setCurrentCert(certData);
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    loadCurrentCert();
  }, [loadCurrentCert]);

  return (
    <Wrapper>
      <Title>
        ใบรับรองการเข้าอบรมหลักสูตร <br />
        {currentCert?.courseName}
      </Title>
      {isLoading ? (
        <LoadingImage></LoadingImage>
      ) : (
        <CertGeneration certification={currentCert}></CertGeneration>
      )}
    </Wrapper>
  );
}

export default Certification;
